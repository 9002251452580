import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/@vercel/analytics/dist/next/index.mjs");
import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/next-sanity/dist/visual-editing/VisualEditing.js");
import(/* webpackMode: "eager" */ "/vercel/path0/web/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/components/Layout/Layout.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/components/PageTransition/PageTransition.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/context/GlobalSettings.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/context/WipeContext.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/web/src/styles/global.scss")