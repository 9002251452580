'use client'

import { ReactNode, useContext } from 'react'
import { TransitionRouter } from 'next-transition-router'
import { WipeContext } from '@/context/WipeContext'

type PageTransitionProps = {
  children: ReactNode
}

const PageTransition = ({ children }: PageTransitionProps) => {
  const { $wipe } = useContext(WipeContext)

  return (
    <TransitionRouter
      leave={async next => {
        await $wipe.animateIn()
        next()
      }}
      enter={async next => {
        await $wipe.animateOut()
        next()
      }}
    >
      {children}
    </TransitionRouter>
  )
}

PageTransition.displayName = 'PageTransition'

export default PageTransition
